<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-28 16:27:09
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-12-19 09:19:50
-->
<template>
  <div class="operatingHistory">
    <div class="title-wrap">
      <div class="tag"></div>
      <div class="title">品牌数据</div>
    </div>
    <div class="case-wrap">
      <el-tabs v-model="activeName" type="card">
          <el-tab-pane label="品牌列表" name="first">
              <brandList ref="brandList"></brandList>
          </el-tab-pane>
          <el-tab-pane label="品牌跟进" name="second">
              <brandFollowData ref="brandFollowData"></brandFollowData>
          </el-tab-pane>
          <el-tab-pane label="品牌活动" name="thirdly">
              <brandActivity ref="brandActivity"></brandActivity>
          </el-tab-pane>
          <el-tab-pane label="领券列表" name="fourthly">
              <couponDetails ref="couponDetails"></couponDetails>
          </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import brandList from './brandComponents/brandList'
import brandFollowData from './brandComponents/brandFollowData'
import brandActivity from './brandComponents/brandActivity'
import couponDetails from './brandComponents/couponDetails'
export default {
  components:{
    brandList,
    brandFollowData,
    brandActivity,
    couponDetails
  },
  mixins:[pageMixin],
  data () {
    return {
      pageMixin_index:'brandData',
      activeName: 'first',
    }
  },
}
</script>

<style lang='scss'>
.operatingHistory{
  background: rgb(240, 242, 245);
  padding: 10px 25px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  .title-wrap{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 5px 0;
    .tag{
      width: 4px;
      height: 20px;
      background: #409EFF;
      border-radius: 10px;
    }
    .title{
      font-size: 18px;
      font-weight: bold;
      padding-left: 10px;
    }
  }
  .case-wrap{
    background: #fff;
    padding: 20px 20px 5px 20px;
    margin-top: 20px;
    border-radius: 6px;
  }
}
</style>

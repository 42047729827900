<!--
 * @Descripttion: 品牌活动
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-08-18 14:37:10
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-08-19 15:07:40
-->
<template>
    <div class="brandActivity">
        <div class="table-wrap" >
            <div class="title-wrap">
                <div class="select-wrap">
                    <el-select filterable clearable v-model="ep_id" @change="selectChange" placeholder="请选择品牌筛选">
                    <el-option
                        v-for="item in epOptions"
                        :key="item.ep_id"
                        :label="item.name"
                        :value="item.ep_id">
                    </el-option>
                    </el-select>
                </div>
                <div class="search-wrap">
                    <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                    <el-button @click="clickSearch" type="primary">搜索</el-button>
                </div>
            </div>
            <el-table :data="tableData" height="620" border style="width: 100%" v-loading="loading">
                <el-table-column align="center" prop="id" label="ID" width="80"></el-table-column>
                <el-table-column align="center" prop="enterprise.name" label="企业名称" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="enterprise_brand.name" label="品牌名称" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="pname" label="方案名称" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="contact_name" label="轮播图" width="100">
                    <template slot-scope="scope">
                        <div v-if="scope.row.home_swiper !=null">
                        <img width="60" @click="clickImage(scope.row.home_swiper)" 
                            v-if="scope.row.home_swiper.length>0" 
                            :src="scope.row.home_swiper[0].img" alt="">
                        </div>
                    </template>
                </el-table-column>
                <!-- <el-table-column align="center" prop="remarks" label="海报" width="100">
                    <template slot-scope="scope">
                        <img height="60"  @click="clickImage(scope.row.home_poster)" 
                            v-if="scope.row.home_poster !=null || scope.row.home_poster.length>0" 
                            :src="scope.row.home_poster[0].img" alt="">
                    </template>
                </el-table-column> -->
                <el-table-column align="center" prop="time_str" label="开始时间" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="time_end" label="结束时间" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="activity_plan_count" label="参与门店数" width="120"></el-table-column>
                <el-table-column align="center" prop="enterprise_activity_count" label="活动数" width="100"></el-table-column>
                <el-table-column align="center" prop="create_timex" label="状态" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div v-if="scope.row.state == 0">下架</div>
                        <div v-if="scope.row.state == 1">上架</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="create_time" label="创建时间" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column fixed="right" align="center" prop="create_timex" label="操作" width="100">
                    <template slot-scope="scope">
                        <div class="operation-wrap">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <i @click="clickInfo(scope.row)" class="iconfont el-icon-document"></i>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-wrap">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="page"
                    :page-size="10"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <div v-if="isShowImages">
            <el-image-viewer 
            :on-close="closeViewer" 
            :url-list="imageList" />
        </div>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
    components: {
      ElImageViewer,
    },
    data () {
        return {
            keyword: '',
            ep_id: '',
            page: 1,
            total: 0,
            tableData: [],
            epOptions: [],
            loading: false,
            isShowImages: false,
            imageList: [],
        }
    },
    mounted () {
        this.getEpConditions();
        this.getActivityList();
    },
    methods: {
        // 详情
        clickInfo(row){
            localStorage.removeItem('activity')
            let newPage = this.$router.resolve({ 
                path: 'activityDataInfo', 
            });
            window.open(newPage.href, '_blank');
            localStorage.setItem('activity',JSON.stringify(row));
        },
        clickImage(item){
            this.isShowImages = true;
            item.forEach(v=>{
                this.imageList.push(v.img);
            })
        },
        // 关闭查看器
        closeViewer() {
            this.isShowImages = false;
            this.imageList = [];
        },
        selectChange(){
            this.page = 1;
            this.getActivityList();
        },
        // 搜索
        clickSearch(){
            this.page = 1;
            this.getActivityList();
        },
        // 获取品牌列表
        getEpConditions(){
            common.connect("/customerservicev1/enterprise_brand/EpConditions",{},(res)=>{
                this.epOptions = res.data
            });
        },
        // 获取活动列表
        getActivityList(){
            let params = {
                keyword: this.keyword,
                page: this.page,
                ep_id: this.ep_id
            }
            this.loading = true;
            common.connect("/customerservicev1/enterprise_activity/enterprisePlanIndex",params,(res)=>{
                res.data.list.forEach(v => {
                    if(v.home_swiper != null){
                        v.home_swiper = JSON.parse(v.home_swiper);
                    }
                    // if(v.home_poster != null){
                    //     v.home_poster = JSON.parse(v.home_poster);
                    // }
                });
                this.tableData = res.data.list;
                this.total = res.data.count;
                this.loading = false;
            });
        },
        handleCurrentChange(val){
            this.page = val;
            this.getActivityList();
        }
    },
}
</script>

<style lang='scss'>
.brandActivity{
   background: #fff;
    .table-wrap{
        .title-wrap{
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            .select-wrap{
            margin-right: 20px;
            }
            .search-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .el-input{
                width: 200px;
                margin-right: 10px;
            }
            }
        }
    }
    .operation-wrap{
        .iconfont{
            font-size: 16px;
            padding: 0 10px;
            cursor: pointer;
        }
    }
    .pagination-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 5px;
    }
}
</style>

<!--
 * @Descripttion: 品牌列表
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-08-02 14:48:28
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-03-30 14:53:52
-->
<template>
    <div class="brandList">
        <div class="table-wrap" >
            <div class="title-wrap">
            <div class="search-wrap">
                <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                <el-button @click="clickSearch" type="primary">搜索</el-button>
            </div>
            <el-button @click="clickCreation" type="primary"
                v-if="userInfo.kf_level == 4 || userInfo.kf_level == 1">添加品牌
            </el-button>
            </div>
            <div class="tag-wrap">
                <div class="tw-item">
                    <div class="item" style="border: 1px solid #ddd;"></div>正常
                </div>
                <div class="tw-item">
                    <div class="item" style="background: #e6a23c"></div>即将过期
                </div>
                <div class="tw-item">
                    <div class="item" style="background: #f56c6c"></div>已过期
                </div>
            </div>
            <el-table :data="tableData" height="600" border style="width: 100%" v-loading="loading" :row-class-name="tableRowClassName">
            <el-table-column align="center" prop="id" label="ID" width="60"></el-table-column>
            <el-table-column align="center" prop="enterprise_name" label="企业名称" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column align="center" prop="name" label="品牌名称" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column align="center" prop="name" label="品牌logo" width="120">
                <template slot-scope="scope">
                <img v-if="scope.row.logo != 'undefined'" :src="scope.row.logo" width="50"/>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="name" label="合作类型" width="120">
                <template slot-scope="scope">
                    <div v-if="scope.row.cooperate_type == 1">正规</div>
                    <div v-if="scope.row.cooperate_type == 2">3D云设计</div>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="shop_count" label="门店数" width="100"></el-table-column>
            <el-table-column align="center" prop="user_count" label="账号数" width="100"></el-table-column>
            <el-table-column align="center" prop="shop_auth_count" label="门店认证数" width="100"></el-table-column>
            <el-table-column align="center" prop="apply_auth_count" label="支付认证数" width="100"></el-table-column>
            <el-table-column align="center" prop="system_admin_name" label="市场负责人" width="100"></el-table-column>
            <el-table-column align="center" prop="is_cooperate" label="是否合作" width="80" v-if="userInfo.kf_level == 1 || userInfo.kf_level == 4">
                <template slot-scope="scope">
                <div v-if="scope.row.is_cooperate == 1">是</div>
                <div v-if="scope.row.is_cooperate == 0">否</div>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="unit_price" label="品牌单价" width="100">
                <template slot-scope="scope">
                    <div>{{scope.row.unit_price/100}}</div>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="unit_price_dealer" label="经销商单价" width="120">
                 <template slot-scope="scope">
                    <div>{{scope.row.unit_price_dealer/100}}</div>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="service_price" label="服务单价" width="120">
              <template slot-scope="scope">
                <div>{{scope.row.service_price/100}}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="create_time" label="创建时间" width="120" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column align="center" fixed="right" label="操作" width="140">
                <template slot-scope="scope">
                <div class="operation-wrap">
                    <el-tooltip class="item" effect="dark" content="详情" placement="top">
                    <i @click="clickInfo(scope.row)" class="iconfont el-icon-document"></i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                        <i v-if="scope.row.is_cooperate == 0" @click="clickEdit(scope.row)" class="iconfont el-icon-edit"></i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="删除" placement="top">
                        <i v-if="scope.row.is_cooperate == 0" @click="clickDel(scope.row)" class="iconfont el-icon-delete"></i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="合作" placement="top">
                        <i v-if="scope.row.is_cooperate == 0 && userInfo.kf_level == 1"
                        @click="clickCollaborate(scope.row)" class="iconfont el-icon-wind-power">
                        </i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="续费" placement="top">
                        <i v-if="scope.row.is_cooperate == 1 && userInfo.kf_level == 1"
                        @click="clickRenew(scope.row)" class="iconfont el-icon-postcard">
                        </i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="修改价格" placement="top">
                        <i v-if="scope.row.is_cooperate == 1 && userInfo.kf_level == 1"
                        @click="clickPrice(scope.row)" class="iconfont el-icon-edit-outline">
                        </i>
                    </el-tooltip>
                </div>
                </template>
            </el-table-column>
            </el-table>
            <div class="pagination-wrap">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="10"
                layout="total, prev, pager, next"
                :total="total">
            </el-pagination>
            </div>
        </div>
        <el-dialog
        :visible.sync="isCreateRecord"
        :before-close="handleClose"
        custom-class="dialog-wrap"
        :close-on-click-modal="false">
        <div class="title" v-if="editId<0">添加品牌数据</div>
        <div class="title" v-else>编辑品牌数据</div>
        <div class="content">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="企业" prop="e_id">
                    <el-select v-model="ruleForm.e_id" placeholder="请选择企业">
                        <el-option v-for="(item,index) in epList"
                            :label="item.label"
                            :value="item.value"
                            :key="index">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="品牌名称" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="合作类型" prop="cooperate_type" v-if="userInfo.kf_level == 1">
                    <el-select v-model="ruleForm.cooperate_type" :change="cooperate_change()">
                        <el-option v-for="(item,index) in cooperateList"
                            :label="item.label"
                            :value="item.value"
                            :key="index">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="账号前缀" prop="prefix" v-if="ruleForm.cooperate_type == 2" trigger='blur'>
                    <el-input v-model="ruleForm.prefix" maxlength="4" minlength="4"></el-input>
                </el-form-item>
                <el-form-item label="品牌logo" prop="logo">
                    <div class="upload-wrap">
                        <aliyun-upload
                            :limitSize="5" :limit="1" :isShopTip="false" v-model="image"
                            :btnDisplay="image.length<1" listType="picture-card">
                        </aliyun-upload>
                        <div v-if="image.length>0" class="fileList-box">
                            <div @click="delImageList" class="delFile">x</div>
                            <el-image fit="contain" class="pic"
                                :preview-src-list="image" :src="image[0]"/>
                        </div>
                        <div>只能上传 jpg,jpeg,png,gif 格式文件，且不超过5M </div>
                    </div>
                </el-form-item>
                <el-form-item label="经销商数" prop="shop_num">
                    <el-input v-model="ruleForm.shop_num"></el-input>
                </el-form-item>
                <el-form-item label="品牌描述" prop="content">
                    <el-input type="textarea" v-model="ruleForm.content"></el-input>
                </el-form-item>
                <el-form-item label="市场专员" prop="system_admin_id" v-if="userInfo.kf_level == 1">
                    <el-select v-model="ruleForm.system_admin_id" placeholder="请选择市场专员">
                        <el-option v-for="(item,index) in marketerList"
                            :label="item.label"
                            :value="item.value"
                            :key="index">
                        </el-option>
                    </el-select>
                </el-form-item>

            </el-form>
            <div class="btn-wrap">
                <el-button v-if="editId<0" :loading="btnLoading" @click="submitForm" type="primary">添 加</el-button>
                <el-button v-else :loading="btnLoading" @click="submitFormEdit" type="primary">编 辑</el-button>
            </div>
        </div>
        </el-dialog>
        <!-- 达成合作 -->
        <el-dialog
        :visible.sync="isCollaborate"
        :before-close="handleCloseRate"
        custom-class="dialog-wrap"
        :close-on-click-modal="false">
        <div class="title">达成合作关系</div>
        <div class="content rate">
            <el-form :model="rateForm" :rules="rateRules" ref="rateForm" label-width="150px" class="demo-ruleForm">
                <el-form-item label="品牌单价" prop="unit_price">
                    <el-input oninput="value=value.replace(/[^\d.]/g,'')" v-model="rateForm.unit_price" placeholder="请输入品牌单价"></el-input>
                </el-form-item>
                <el-form-item label="经销商单价" prop="unit_price_dealer">
                    <el-input oninput="value=value.replace(/[^\d.]/g,'')" v-model="rateForm.unit_price_dealer" placeholder="请输入经销商单价"></el-input>
                </el-form-item>
              <el-form-item label="服务单价" prop="service_price">
                <el-input oninput="value=value.replace(/[^\d.]/g,'')" v-model="rateForm.service_price" placeholder="请输入服务单价"></el-input>
              </el-form-item>
                <el-form-item label="经销商划线价" prop="unit_price_dealer_scribing">
                    <el-input oninput="value=value.replace(/[^\d.]/g,'')" v-model="rateForm.unit_price_dealer_scribing" placeholder="请输入经销商划线价"></el-input>
                </el-form-item>
                <el-form-item label="合约到期日期" prop="contract_end_time">
                    <el-date-picker
                        v-model="rateForm.contract_end_time"
                        type="date"
                        :picker-options="option"
                        placeholder="请选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="企业版初始账号" prop="username">
                    <el-input v-model="rateForm.username" placeholder="请输入企业版初始账号"></el-input>
                </el-form-item>
                <el-form-item label="企业版初始密码" prop="password">
                    <el-input v-model="rateForm.password" placeholder="请输入企业版初始密码"></el-input>
                </el-form-item>
                <el-form-item label="企业版绑定手机号码" prop="mobile">
                    <el-input v-model="rateForm.mobile" placeholder="请输入企业版绑定手机号码"></el-input>
                </el-form-item>
            </el-form>
            <div class="btn-wrap">
                <el-button :loading="btnLoading" @click="submitRate" type="primary">确 定</el-button>
            </div>
        </div>
        </el-dialog>
        <!-- 修改价格 -->
        <el-dialog
        :visible.sync="isshowPrice"
        :before-close="handleClosePrice"
        custom-class="dialog-wrap"
        :close-on-click-modal="false">
        <div class="title">修改价格</div>
        <div class="content rate">
            <el-form :model="priceForm" :rules="priceRules" ref="priceForm" label-width="150px" class="demo-ruleForm">
                <el-form-item label="品牌单价" prop="unit_price">
                    <el-input oninput="value=value.replace(/[^\d.]/g,'')" v-model="priceForm.unit_price" placeholder="请输入经销商单价"></el-input>
                </el-form-item>
                <el-form-item label="经销商单价" prop="unit_price_dealer">
                    <el-input oninput="value=value.replace(/[^\d.]/g,'')" v-model="priceForm.unit_price_dealer" placeholder="请输入经销商单价"></el-input>
                </el-form-item>
                <el-form-item label="服务单价" prop="service_price">
                  <el-input oninput="value=value.replace(/[^\d.]/g,'')" v-model="priceForm.service_price" placeholder="请输入服务单价"></el-input>
                </el-form-item>
                <el-form-item label="经销商划线价" prop="unit_price_dealer_scribing">
                    <el-input oninput="value=value.replace(/[^\d.]/g,'')" v-model="priceForm.unit_price_dealer_scribing" placeholder="请输入经销商划线价"></el-input>
                </el-form-item>
                <el-form-item label="到期时间">
                  <el-date-picker
                      v-model="priceForm.expiration_time"
                      type="date"
                      placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="品牌添加">
                  <el-switch
                      v-model="priceForm.brand_add" :active-value="1" :inactive-value="0">
                  </el-switch>
                </el-form-item>
            </el-form>
            <div class="btn-wrap">
                <el-button :loading="btnLoading" @click="submitPrice" type="primary">确 定</el-button>
            </div>
        </div>
        </el-dialog>
        <!-- 续费 -->
        <el-dialog
        :visible.sync="isshowRenew"
        :before-close="handleCloseRenew"
        custom-class="dialog-renew-wrap"
        :close-on-click-modal="false">
        <div class="title">续费</div>
        <div class="renew">
            <el-date-picker
            v-model="renewDate"
            type="date"
            :picker-options="option"
            placeholder="请选择日期">
            </el-date-picker>
            <div class="btn-wrap">
                <el-button :loading="btnLoading" @click="submitRenew" type="primary">确 定</el-button>
            </div>
        </div>
        </el-dialog>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import {timestampToTime} from "@/utils/timeCycle";
import AliyunUpload from "@/components/upload/AliyunUpload.vue";
export default {
    components:{
        AliyunUpload,
    },
    data () {
        return {
            userInfo: {},
            tableData: [],
            loading: false,
            keyword: '',
            currentPage: 1,
            total: 0,
            isCreateRecord: false,
            isCollaborate: false,
            isshowRenew: false,
            isshowPrice: false,
            renewDate: '',
            renewId: -1,
            editId: -1,
            image: [],
            ruleForm: {
                e_id: '',
                name: '',
                prefix: '',
                logo: '',
                shop_num: '',
                content: '',
                system_admin_id: '',
                cooperate_type: 1
            },
            rules: {
                name: [
                    { required: true, message: '请输入品牌名称', trigger: 'blur' },
                ],
                cooperate_type: [
                    { required: true, message: '请选择合作类型', trigger: 'blur' },
                ],
                prefix:[],
                e_id: [
                    { required: true, message: '请选择企业', trigger: 'change' }
                ],
                system_admin_id: [
                    { required: true, message: '请选择市场专员', trigger: 'change' }
                ],

            },
            epList: [],
            marketerList: [],
            cooperateList: [{
                label: '正规',
                value: 1
            },{
                label: '3D云设计',
                value: 2
            }],
            btnLoading: false,
            option: {
                disabledDate:(time)=> {
                    return time.getTime() < Date.now()-1 * 24 * 3600 * 1000
                }
            },
            rateForm: {
                unit_price: '',
                unit_price_dealer: '',
                unit_price_dealer_scribing: '',
                expiration_time: '',
                brand_add: '',
                service_price: '',
                contract_end_time: '',
                username: '',
                password: '',
                mobile: ''
            },
            rateRules: {
                unit_price: [
                    { required: true, message: '请输入品牌单价', trigger: 'blur' },
                ],
                unit_price_dealer: [
                    { required: true, message: '请输入经销商单价', trigger: 'blur' },
                ],
                unit_price_dealer_scribing: [
                    { required: true, message: '请输入经销商划线价', trigger: 'blur' },
                ],
                service_price: [
                  { required: true, message: '请输入服务单价', trigger: 'blur' },
                ],
                contract_end_time: [
                    { required: true, message: '请选择日期', trigger: 'change' }
                ],
                username: [
                    { required: true, message: '请输入企业版初始账号', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入企业版初始密码', trigger: 'blur' },
                    { min: 6, message: '密码长度不能少于6位数字', trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请输入企业版绑定手机号码', trigger: 'blur' }
                ]
            },
            rateId: 0,
            priceForm: {
                priceID: '',
                unit_price: '',
                unit_price_dealer: '',
                unit_price_dealer_scribing: '',
                expiration_time: '',
                brand_add: '',
                service_price: '',
            },
            priceRules: {
                unit_price: [
                    { required: true, message: '请输入品牌单价', trigger: 'blur' },
                ],
                unit_price_dealer: [
                    { required: true, message: '请输入经销商单价', trigger: 'blur' },
                ],
                unit_price_dealer_scribing: [
                    { required: true, message: '请输入经销商划线价', trigger: 'blur' },
                ],
                service_price: [
                  { required: true, message: '请输入服务单价', trigger: 'blur' },
                ],
            },
        }
    },
    mounted () {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.getIndexList();
        this.getConditions();
        this.getMarketUser();
    },
    watch: {
        image(newData, oldData) {
            this.ruleForm.logo = newData[0]
        }
    },
    methods: {
        cooperate_change(e){
            // console.log(this.rules);
            // console.log(this.ruleForm.cooperate_type);
            if(this.ruleForm.cooperate_type==2){
                this.rules.prefix=[
                    { required: true, message: '请输入品账号前缀', trigger: 'blur' },
                ]
            }
            // console.log(this.rules);
        },
        // 修改价格
        submitPrice(){
            let params = {
                id: this.priceForm.priceID,
                unit_price: this.priceForm.unit_price*100,
                unit_price_dealer: this.priceForm.unit_price_dealer*100,
                service_price: this.priceForm.service_price*100,
                unit_price_dealer_scribing: this.priceForm.unit_price_dealer_scribing*100,
                expiration_time: timestampToTime(this.priceForm.expiration_time),
                brand_add: this.priceForm.brand_add,
            }
            this.btnLoading = true;
            common.connect("/customerservicev1/enterprise_brand/updatePrice",params,(res)=>{
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                this.getIndexList();
                this.btnLoading = false;
                this.isshowPrice = false;
            });
        },
        // 续费
        submitRenew(){
            if(this.renewDate == ''){
                this.$message.error('请选择续费到期日期！');
                return;
            }
            let params = {
                id: this.renewId,
                contract_end_time: timestampToTime(this.renewDate)
            }
            this.btnLoading = true;
            common.connect("/customerservicev1/enterprise_brand/renew",params,(res)=>{
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                this.getIndexList();
                this.btnLoading = false;
                this.isshowRenew = false;
                this.renewId = -1;
            });
            setTimeout(()=>{
                this.btnLoading = false;
            },3000)
        },
        clickPrice(row){
            this.priceForm.unit_price = row.unit_price/100;
            this.priceForm.unit_price_dealer = row.unit_price_dealer/100;
            this.priceForm.unit_price_dealer_scribing = row.unit_price_dealer_scribing/100
            this.priceForm.expiration_time = row.expiration_time
            this.priceForm.brand_add = row.brand_add
            this.priceForm.service_price = row.service_price/100
            this.priceForm.priceID = row.id;
            this.isshowPrice = true;
        },
        clickRenew(row){
            this.renewDate = row.contract_end_time;
            this.renewId = row.id;
            this.isshowRenew = true;
        },
        handleClosePrice(){
            this.isshowPrice = false;
            this.$refs.priceForm.resetFields();
        },
        handleCloseRenew(){
            this.isshowRenew = false;
            this.renewId = -1
        },
        // 判断
        tableRowClassName({row, rowIndex}){
            if(row.ep_status === 2){
                return 'warning-row';
            }else if(row.ep_status === 3){
                return 'danger-row';
            }
        },
        // 达成合作
        submitRate(){
        this.$refs.rateForm.validate((valid) => {
            if (valid) {
            let params = {
                id: this.rateId,
                unit_price: Number(this.rateForm.unit_price)*100,
                unit_price_dealer: Number(this.rateForm.unit_price_dealer)*100,
                unit_price_dealer_scribing: Number(this.rateForm.unit_price_dealer_scribing)*100,
                service_price: Number(this.rateForm.service_price)*100,
                contract_end_time: timestampToTime(this.rateForm.contract_end_time),
                username: this.rateForm.username,
                password: this.rateForm.password,
                mobile: this.rateForm.mobile,
            }
            this.btnLoading = true;
            common.connect("/customerservicev1/enterprise_brand/cooperation",params,(res)=>{
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                this.getIndexList();
                this.isCollaborate = false;
                this.btnLoading = false;
                this.$refs.rateForm.resetFields();
            });
            setTimeout(()=>{
                this.btnLoading = false;
            },3000)
            } else {
                console.log('error submit!!');
                return false;
            }
        });
        },
        handleCloseRate(){
        this.isCollaborate = false;
        this.$refs.rateForm.resetFields();
        },
        // 合作
        clickCollaborate(row){
        this.rateId = row.id;
        this.isCollaborate = true;
        },
        // 创建
        submitForm(){
        this.$refs.ruleForm.validate((valid) => {
        if (valid) {
            this.btnLoading = true;
            common.connect("/customerservicev1/enterprise_brand/store",this.ruleForm,(res)=>{
            this.$message({
                message: res.msg,
                type: 'success'
            });
            this.getIndexList();
            this.isCreateRecord = false;
            this.btnLoading = false;
            this.image = [];
            // this.honorImgs = [];
            this.$refs.ruleForm.resetFields();
            });
            setTimeout(()=>{
            this.btnLoading = false;
            },2000)
        } else {
            console.log('error submit!!');
            return false;
        }
        });
        },
        // 确定编辑
        submitFormEdit(){
        this.$refs.ruleForm.validate((valid) => {
            if (valid) {
            this.ruleForm.id = this.editId;
            this.btnLoading = true;
            common.connect("/customerservicev1/enterprise_brand/update",this.ruleForm,(res)=>{
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                this.getIndexList();
                this.btnLoading = false;
                this.isCreateRecord = false;
                this.editId = -1;
                this.image = [];
                this.ruleForm.name = '';
                this.$refs.ruleForm.resetFields();
            });
            setTimeout(()=>{
                this.btnLoading = false;
            },2000)
            } else {
            console.log('error submit!!');
            return false;
            }
        });
        },
        // 编辑页
        clickEdit(row){
            this.editId = row.id;
            common.connect("/customerservicev1/enterprise_brand/edit",{id: row.id},(res)=>{
                this.isCreateRecord = true;
                this.ruleForm.e_id = res.data.e_id;
                this.ruleForm.name = res.data.name;
                this.ruleForm.logo = res.data.logo;
                this.ruleForm.shop_num = res.data.shop_num;
                this.ruleForm.content = res.data.content;
                this.ruleForm.cooperate_type = res.data.cooperate_type;
                this.ruleForm.system_admin_id = res.data.system_admin_id;
                this.image[0] = res.data.logo;
            });
        },
        // 删除
        clickDel(row){
            this.$confirm('此操作将永久删除该品牌, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect("/customerservicev1/enterprise_brand/delete",{id: row.id},(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getIndexList();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        //删除logo图片
        delImageList(){
            this.image.splice(0, 1)
        },
        delHonorList(index){
        this.ruleForm.honor_imgs.splice(index, 1)
        },
        // 获取市场专员数据
        getMarketUser(){
        common.connect("/customerservicev1/admin/marketUserConditions",{},(res)=>{
            let resultSecond =  res.data.map(v =>{
            return{
                value: v.user_id,
                label: v.nickname
            }
            })
            this.marketerList = resultSecond;
        });
        },
        // 获取企业数据
        getConditions(){
        common.connect("/customerservicev1/enterprise/eConditions",{},(res)=>{
            let resultSecond =  res.data.map(v =>{
            return{
                value: v.e_id,
                label: v.name
            }
            })
            this.epList = resultSecond;
        });
        },
        // 点击创建
        clickCreation(){
            this.isCreateRecord = true;
        },
        // 关闭弹窗
        handleClose(){
            this.isCreateRecord = false;
            this.$refs.ruleForm.resetFields();
            this.editId = -1;
            this.image = [];
            this.ruleForm.name = '';
        },
        // 跳转详情
        clickInfo(row){
            localStorage.removeItem('brand')
            // this.$router.push({ name:'brandDataInfo'})
            let newPage = this.$router.resolve({
                path: 'brandDataInfo',
            });
            window.open(newPage.href, '_blank');
            localStorage.setItem('brand',JSON.stringify(row));
        },
        // 搜索
        clickSearch(){
            this.currentPage = 1;
            this.getIndexList()
        },
        // 获取列表数据
        getIndexList(){
            let params = {
                keyword: this.keyword,
                page: this.currentPage
            }
            this.loading = true;
            common.connect("/customerservicev1/enterprise_brand/index",params,(res)=>{
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            });
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getIndexList();
        }
    },
}
</script>

<style lang='scss'>
.brandList{
    .table-wrap{
        .title-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 20px;
            .select-wrap{
            margin-right: 20px;
            }
            .search-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .el-input{
                width: 200px;
                margin-right: 10px;
            }
            }
        }
        .tag-wrap{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-bottom: 10px;
            margin-top: -10px;
            .tw-item{
            font-size: 14px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-left: 10px;
            .item{
                margin-right: 5px;
                width: 12px;
                height: 12px;
                border-radius: 100%;
                background: #fff;
            }
            }
        }
        .operation-wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            .iconfont{
            font-size: 16px;
            padding: 0 8px;
            cursor: pointer;
            }
        }
    }
    .pagination-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 5px;
    }
    .dialog-renew-wrap{
            width: 500px;
            .title{
                display: flex;
                justify-content: center;
                font-size: 17px;
                color: #000;
                font-weight: bold;
                padding-top: 20px;
            }
            .renew{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-left: 20px;
                padding: 20px 0;
                .el-input{
                    width: 455px !important;
                }
                .btn-wrap{
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-right: 25px;
                padding-top: 20px;
            }
            }
        }
    .dialog-wrap{
        width: 800px;
        .title{
        font-size: 18px;
        color: #000;
        margin-left: 20px;
        }
        .renew{
        .el-input{
            width: 760px !important;
            margin-bottom: 30px;
        }
        }
        .rate{
        .el-input{
            width: 600px !important;
        }
        .el-textarea{
            width: 600px !important;
        }
        }
        .content{
            max-height: 80vh;
            overflow-y: auto;
            margin-left: 20px;
            padding: 30px 0;
            .el-input{
                width: 650px;
            }
            .el-textarea{
                width: 650px;
            }
            .upload-wrap{
            padding-top: 2px;
            margin-bottom: -10px;
            .image-wrap{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                width: 100%;
                margin-top: 10px;
            }
            .fileList-box{
                width: 100px;
                height: auto;
                position: relative;
                margin-right: 10px;
                .delFile{
                position: absolute;
                top: 5px;
                left: 5px;
                cursor: pointer;
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                    border: 1px #ddd solid;
                    background: rgba(100, 100, 100, 0.5);
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 99;
                }
                .pic{
                width: 100%;
                }
            }
        }
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .el-table tbody tr:hover>td {
        background-color: transparent !important;
    }
    .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf  {
        background-color: transparent !important;
    }
    .el-table__fixed-right tbody tr:hover>td {
        background-color: transparent !important;
    }
}
</style>

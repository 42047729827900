<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-08-02 15:04:01
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-09-21 11:28:56
-->
<template>
    <div class="brandFollowData">
        <commonSelect
          @changeDate="changeDate"
          @clickTagDate="clickTagDate"
        ></commonSelect>
        <div class="table-wrap" >
            <div class="title-wrap">
                <div class="select-wrap" v-if="userInfo.kf_level == 1">
                    <el-select clearable v-model="userId" @change="selectChange" placeholder="请选择用户筛选">
                    <el-option
                        v-for="item in userOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                    </el-select>
                </div>
                <div class="search-wrap">
                    <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                    <el-button @click="clickSearch" type="primary">搜索</el-button>
                </div>
            </div>
            <el-table :data="tableData" height="580" border style="width: 100%" v-loading="loading">
                <el-table-column align="center" prop="id" label="ID" width="100"></el-table-column>
                <el-table-column align="center" prop="enterprise_brand.name" label="品牌名称" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="system_admin.nickname" label="客服负责人" width="100"></el-table-column>
                <el-table-column align="center" prop="title" label="标题" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="contact_name" label="联系人" width="150"></el-table-column>
                <el-table-column align="center" prop="remarks" label="备注" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="create_timex" label="填写时间" width="200"></el-table-column>
                <el-table-column align="center" prop="create_timex" label="操作" width="100">
                    <template slot-scope="scope">
                        <div class="operation-wrap">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <i @click="clickInfo(scope.row)" class="iconfont el-icon-document"></i>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-wrap">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="10"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import commonSelect from "@/components/commonSelect.vue"
import common from "@/utils/common.js"
export default {
    components:{
        commonSelect
    },
    data () {
        return {
            userInfo: {},
            tableData: [],
            loading: false,
            keyword: '',
            userId: '',
            dateType: '',
            startDate: '',
            endDate: '',
            currentPage: 1,
            total: 0,
            userOptions: []
        }
    },
    mounted () {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.getIndexList()
        this.getUserConditions();
    },
    methods: {
        // 跳转详情
        clickInfo(row){
            localStorage.removeItem('brand')
            let newPage = this.$router.resolve({ 
                path: 'brandDataInfo', 
                query: { type: 'fourthly' }
            });
            window.open(newPage.href, '_blank');
            localStorage.setItem('brand',JSON.stringify(row));
        },
        // 日期选择
        changeDate(startDate,endDate){
            this.currentPage = 1;
            this.startDate = startDate.slice(0,11)+' 00:00:01';
            this.endDate = endDate.slice(0,11)+' 23:59:59';
            this.getIndexList()
        },
        clickTagDate(item){
            this.dateType = item.label;
            this.currentPage = 1;
            if(item.label != 'date'){
                this.startDate = '';
                this.endDate = '';
                this.getIndexList();
            }
        },
        // 搜索
        clickSearch(){
            this.currentPage = 1;
            this.getIndexList()
        },
        // 更据用户筛选
        selectChange(){
            this.currentPage = 1;
            this.getIndexList();
        },
        // 获取用户列表
        getUserConditions(){
            common.connect("/customerservicev1/admin/marketUserConditions",{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.user_id,
                    label: v.nickname
                }
                })
                this.userOptions = resultSecond;
            });
        },
        // 获取列表数据
        getIndexList(){
            let params = {
                keyword: this.keyword,
                user_id: this.userId,
                ep_id: '',
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                page: this.currentPage
            }
            this.loading = true;
            common.connect("/customerservicev1/enterprise_brand_follow/index",params,(res)=>{
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            });
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getIndexList();
        }
    },
}
</script>

<style lang='scss'>
.brandFollowData{
    background: #fff;
    .table-wrap{
        padding-top: 10PX;
        .title-wrap{
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            .select-wrap{
            margin-right: 20px;
            }
            .search-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .el-input{
                width: 200px;
                margin-right: 10px;
            }
            }
        }
    }
    .operation-wrap{
        .iconfont{
            font-size: 16px;
            padding: 0 10px;
            cursor: pointer;
        }
    }
    .pagination-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 5px;
    }
}
</style>

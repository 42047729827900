<template>
    <div class="couponDetails">
        <commonSelect
          @changeDate="changeDate"
          @clickTagDate="clickTagDate"
        ></commonSelect>
        <div class="table-wrap" >
            <div class="tw-title">
                <div class="title-wrap">
                    <div class="select-wrap">
                        <el-select filterable clearable v-model="ep_id" @change="selectChange" placeholder="请选择品牌筛选">
                        <el-option
                            v-for="item in epOptions"
                            :key="item.ep_id"
                            :label="item.name"
                            :value="item.ep_id">
                        </el-option>
                        </el-select>
                    </div>
                    <div class="select-wrap" v-if="ep_id != ''">
                        <el-select filterable clearable v-model="p_plan_id" @change="selectsChemeChange" placeholder="请选择活动方案筛选">
                        <el-option
                            v-for="item in schemeOptions"
                            :key="item.id"
                            :label="item.pname"
                            :value="item.id">
                        </el-option>
                        </el-select>
                    </div>
                    <div class="select-wrap" v-if="p_plan_id != ''">
                        <el-select filterable clearable v-model="p_activity_id" @change="selectsActivityChange" placeholder="请选择活动筛选">
                        <el-option
                            v-for="item in activityOptions"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id">
                        </el-option>
                        </el-select>
                    </div>
                    <div class="select-wrap">
                        <el-select filterable clearable v-model="type" @change="selectTypeChange" placeholder="请选择活动类型筛选">
                        <el-option
                            v-for="item in typeOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                        </el-select>
                    </div>
                    <!-- <div class="search-wrap">
                        <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                        <el-button @click="clickSearch" type="primary">搜索</el-button>
                    </div> -->
                </div>
                <el-button v-if="user.kf_level == 1" :loading="btnLoading" @click="clickExport" type="success">导出</el-button>
            </div>
            <el-table :data="tableData" height="580" border style="width: 100%" v-loading="loading">
                <el-table-column align="center" prop="id" label="ID" width="80"></el-table-column>
                <el-table-column align="center" prop="enterprise_brand.name" label="品牌名称" width="140" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="dealer_name" label="备注名称" width="140" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="dealer_address" label="备注地区" width="150" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="activity.tag" label="经销商标签一" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="activity.tag2" label="经销商标签二" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="user.username" label="销售名称" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="user.mobile" label="销售电话" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="sys_activity_type.name" label="活动类型" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="activity.title" label="活动名称" width="180" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="activity.guide" label="活动内容" width="200" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="c_user.username" label="客户昵称" width="160" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="c_user.mobile" label="客户手机" width="140"></el-table-column>
                <el-table-column align="center" prop="create_timex" label="领券时间" width="180" :show-overflow-tooltip="true"></el-table-column>
            </el-table>
            <div class="pagination-wrap">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="page"
                    :page-size="50"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <div v-if="isShowImages">
            <el-image-viewer 
            :on-close="closeViewer" 
            :url-list="imageList" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import common from "@/utils/common.js"
import commonSelect from "@/components/commonSelect.vue"
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import {dealdate} from "@/utils/timeCycle";
export default {
    components: {
      ElImageViewer,
      commonSelect
    },
    data () {
        return {
            keyword: '',
            ep_id: '',
            page: 1,
            total: 0,
            tableData: [],
            epOptions: [],
            typeOptions: [],
            schemeOptions: [],
            activityOptions: [],
            type: '',
            loading: false,
            isShowImages: false,
            btnLoading: false,
            imageList: [],
            dateType: '',
            startDate: '',
            endDate: '',
            epName: '',
            p_plan_id: '',
            p_activity_id: '',
            user: {}
        }
    },
    mounted () {
        this.user = JSON.parse(localStorage.getItem('userInfo'));
        this.getEpConditions();
        this.getActivityList();
        this.getCouponType();
    },
    methods: {
        // 导出
        async clickExport(){
            let that = this;
            let token = localStorage.getItem("token");
            var authorization = 'Bearer ' + token;
            let name = this.epName;
            let params = {
                keyword: this.keyword,
                ep_id: this.ep_id,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                type: this.type,
                p_plan_id: this.p_plan_id,
                p_activity_id: this.p_activity_id
            }
            this.btnLoading = true;
            let url = common.apiServer+'/customerservicev1/enterprise_activity/receiveIndexExport'
            axios({
                url: url,
                responseType: "blob",
                method: 'POST',
                data: params,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization,
                }
            }).then(function(res) {
                if(res.status == 200){
                    let day = dealdate(new Date()).slice(0,10)
                    const blob = new Blob([res.data]);
                    const fileName = name+day+'.xls';
                    const linkNode = document.createElement('a');
                    linkNode.download = fileName; //a标签的download属性规定下载文件的名称
                    linkNode.style.display = 'none';
                    linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
                    document.body.appendChild(linkNode);
                    linkNode.click();  //模拟在按钮上的一次鼠标单击
                    URL.revokeObjectURL(linkNode.href); // 释放URL 对象
                    document.body.removeChild(linkNode);
                    that.btnLoading = false;
                }
            })
        },
        // 日期选择
        changeDate(startDate,endDate){
            this.currentPage = 1;
            this.startDate = startDate.slice(0,11)+' 00:00:01';
            this.endDate = endDate.slice(0,11)+' 23:59:59';
            this.getActivityList()
        },
        clickTagDate(item){
            this.dateType = item.label;
            this.currentPage = 1;
            if(item.label != 'date'){
                this.startDate = '';
                this.endDate = '';
                this.getActivityList();
            }
        },
        // 详情
        clickInfo(row){
            localStorage.removeItem('activity')
            let newPage = this.$router.resolve({ 
                path: 'activityDataInfo', 
            });
            window.open(newPage.href, '_blank');
            localStorage.setItem('activity',JSON.stringify(row));
        },
        clickImage(item){
            this.isShowImages = true;
            item.forEach(v=>{
                this.imageList.push(v.img);
            })
        },
        // 关闭查看器
        closeViewer() {
            this.isShowImages = false;
            this.imageList = [];
        },
        selectsActivityChange(){
            this.page = 1;
            this.getActivityList();
        },
        selectsChemeChange(){
            this.page = 1;
            this.p_activity_id = '';
            this.getEnterpriseActivity();
        },
        selectTypeChange(){
            this.page = 1;
            this.getActivityList();
        },
        selectChange(e){
            this.epOptions.forEach(v=>{
                if(v.ep_id == e){
                    this.epName = v.name
                }
            })
            this.page = 1;
            this.p_plan_id = '';
            this.getEnterprisePlan();
        },
        // 搜索
        clickSearch(){
            this.page = 1;
            this.getActivityList();
        },
        // 获取活动选项
        getEnterpriseActivity(){
            let params = {
                ep_id: this.ep_id,
                p_plan_id: this.p_plan_id
            }
            common.connect("/customerservicev1/enterprise_activity/enterpriseActivityConditions",params,(res)=>{
                this.activityOptions = res.data;
                this.getActivityList();
            });
        },
        // 获取活动方案选项
        getEnterprisePlan(){
            let params = {
                ep_id: this.ep_id
            }
            common.connect("/customerservicev1/enterprise_activity/enterprisePlanConditions",params,(res)=>{
                this.schemeOptions = res.data;
                this.getActivityList();
            });
        },
        // 获取活动类型列表
        getCouponType(){
            common.connect("/customerservicev1/enterprise_activity/couponTypeConditions",{},(res)=>{
                this.typeOptions = res.data
            });
        },
        // 获取品牌列表
        getEpConditions(){
            common.connect("/customerservicev1/enterprise_brand/EpConditions",{},(res)=>{
                this.epOptions = res.data
            });
        },
        // 获取活动列表
        getActivityList(){
            let params = {
                keyword: this.keyword,
                page: this.page,
                ep_id: this.ep_id,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                type: this.type,
                p_plan_id: this.p_plan_id,
                p_activity_id: this.p_activity_id
            }
            this.loading = true;
            common.connect("/customerservicev1/enterprise_activity/receiveIndex",params,(res)=>{
                this.tableData = res.data.list;
                this.total = res.data.count;
                this.loading = false;
            });
        },
        handleCurrentChange(val){
            this.page = val;
            this.getActivityList();
        }
    },
}
</script>

<style lang='scss'>
.couponDetails{
   background: #fff;
    .table-wrap{
        .tw-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 10px;
            .title-wrap{
                display: flex;
                align-items: center;
                .select-wrap{
                    margin-right: 20px;
                    margin-bottom: 10px;
                }
                .search-wrap{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    .el-input{
                        width: 200px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    .operation-wrap{
        .iconfont{
            font-size: 16px;
            padding: 0 10px;
            cursor: pointer;
        }
    }
    .pagination-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 5px;
    }
}
</style>
